.page-reset-password {
  background: linear-gradient(0deg, #ffa28b, #f16c4a);
  position: relative;
  overflow: hidden;
}

.reset-password-wrapper {
  font-family: 'Matter';

  .ornament {
    position: absolute;
    border-radius: 2rem;
    background: rgba(241, 109, 76, 0.34);
    transform: rotate(55deg);
    z-index: 0;
    right: 50px;
    bottom: -80%;
    height: 50rem;
    width: 40rem;
  }

  .ornament-top-1 {
    position: absolute;
    border-radius: 1rem;
    background: rgba(241, 163, 82, 0.35);
    transform: rotate(61deg);
    z-index: 0;
    left: 60px;
    top: -55%;
    height: 35rem;
    width: 43rem;
  }

  .ornament-top-2 {
    position: absolute;
    border-radius: 1rem;
    background: rgba(241, 163, 82, 0.35);
    transform: rotate(61deg);
    z-index: 0;
    left: 60px;
    top: -25%;
    height: 20rem;
    width: 25rem;
  }

  h1 {
    margin-bottom: 15px;
    color: #ffffff;
    font-family: 'Messina Sans';
    font-size: 36px;
    font-weight: bold;
    line-height: 47px;
    text-align: center;
  }

  p {
    margin-bottom: 35px;
    color: rgba(255, 255, 255, 0.7);
    font-family: 'Messina Sans';
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .form-reset-password {
    padding: 30px 0;
    width: 380px;
    max-width: 358px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);

    label {
      color: rgba(255, 255, 255, 0.75);
      font-family: 'Messina Sans';
      font-size: 12px;
      line-height: 24px;
    }

    .password-show-hide {
      border: none;
      border-radius: 5px;
      background-color: #fff;
      overflow: hidden;

      input {
        color: #495057;
        font-family: 'Messina Sans';
        font-size: 14px;
        line-height: 24px;
        border: none;
        background: transparent;
        border-radius: 0;
        padding: 5px 8px 5px 8px;
      }
    }

    .btn-send-email {
      color: #ffffff;
      font-family: 'Messina Sans';
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      font-weight: normal;
      height: 50px;
      width: 163px;
      margin-top: 40px;
    }
  }

  .success-submit {
    padding: 30px 0;
    width: 380px;
    max-width: 358px;
    height: 380px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, 0);
    text-align: center;

    h1 {
      margin: 30px 0 15px 0;
    }

    p {
      margin-bottom: 40px;
      width: 359px;
    }

    .btn-send-email {
      margin-bottom: 15px;
      min-width: 150px;
      background-color: #60269e;
      font-weight: normal;
    }
  }

  .footer {
    margin-top: -5vw;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;

    .copyright {
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;
      width: 100%;
    }
  }

  .btn-link {
    color: #f16c4a;
    font-size: 1.17vw;
    line-height: 1.6vw;
    font-weight: normal;
    text-decoration: none;
    padding-bottom: 0.2vw;
  }

  label {
    color: #a6a7b8;
    font-size: 0.94vw;
    line-height: 1.9vw;
    margin-bottom: 0;
  }
}

@media (max-width: 576px) {
  .reset-password-wrapper {
    height: auto !important;

    .btn-link {
      font-size: inherit;
      line-height: inherit;
      font-weight: 100;
      margin-bottom: 1vw;
    }

    .footer {
      position: absolute;
      bottom: 15px;

      .copyright {
        font-size: 13px !important;
        line-height: 15px !important;
        font-weight: 100;
      }
    }
  }
}
