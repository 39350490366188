@import '../../scss/theme';

.page-adminsignin {
  background-color: #f16c4a;
  position: relative;

  .btn-darkprimary {
    background-color: #f16c4a !important;
    border-color: #f16c4a !important;

    &:hover,
    &:focus {
      background-color: #f16c4a !important;
    }
  }
}

.Auth-Admin-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;

  .logo {
    display: inline-block;
    background: linear-gradient(0deg, #eb795b, #f16c4a);
    padding: 10px;
    border-radius: 8px;
  }

  .content-signin {
    display: flex;
    background: #f5f6fb;
    position: relative;
    width: 30%;

    .Auth-Admin {
      width: 380px;
      height: 500px;
      background-color: #fff;
      border-radius: 10px;
      position: absolute;
      left: -100px;
      top: 50%;
      transform: translate(0, -70%);

      svg {
        text-align: center;
      }

      .form-signin {
        padding: 80px 15px 100px;
      }
    }
  }

  .content-welcome {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    color: #fff;
    padding: 100px;
    position: relative;

    .content-text {
      position: absolute;
      top: 40%;
      left: 100;
      transform: translate(0, -70%);
    }

    h1 {
      display: block;
      color: #fff;
      font-size: 42px;
    }

    p {
      color: #fff;
      font-size: 18px;
    }
  }
}
