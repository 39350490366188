.page-tm-email-verification {
  background: linear-gradient(0deg, #ffa28b, #f16c4a);
  position: relative;
  overflow: hidden;

  .verify-email-wrapper {
    font-family: 'Matter';

    .ornament {
      position: absolute;
      border-radius: 2rem;
      background: rgba(241, 109, 76, 0.34);
      transform: rotate(55deg);
      z-index: 0;
      right: 50px;
      bottom: -80%;
      height: 50rem;
      width: 40rem;
    }

    .ornament-top-1 {
      position: absolute;
      border-radius: 1rem;
      background: rgba(241, 163, 82, 0.35);
      transform: rotate(61deg);
      z-index: 0;
      left: 60px;
      top: -55%;
      height: 35rem;
      width: 43rem;
    }

    .ornament-top-2 {
      position: absolute;
      border-radius: 1rem;
      background: rgba(241, 163, 82, 0.35);
      transform: rotate(61deg);
      z-index: 0;
      left: 60px;
      top: -25%;
      height: 20rem;
      width: 25rem;
    }

    h1 {
      margin-bottom: 15px;
      color: #ffffff;
      font-family: 'Messina Sans';
      font-size: 36px;
      font-weight: bold;
      line-height: 47px;
      text-align: left;
    }

    p {
      margin-bottom: 35px;
      color: rgba(255, 255, 255, 0.7);
      font-family: 'Messina Sans';
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }

    .btn-login {
      color: #ffffff;
      font-size: 1.17vw;
      line-height: 2vw;
      text-align: center;
      font-weight: normal;
      margin-top: 1vh;
    }

    .email-verification-container {
      width: 550px;
      max-width: 550px;
      padding: 30px 0;
      position: absolute;
      left: 50%;
      top: 150px;
      transform: translate(-50%, 0);
      text-align: left;
    }

    .container-fluid {
      &.container-custom {
        padding: 1.2vw 5vw 0 5vw !important;
      }
    }

    .footer {
      margin-top: -5vw;
      width: 100%;
      bottom: 0;
      display: flex;
      flex-direction: row;

      .copyright {
        color: #fff;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: center;
        width: 100%;
      }
    }

    .btn-link {
      color: #f16c4a;
      font-size: 1.17vw;
      line-height: 1.6vw;
      font-weight: normal;
      text-decoration: none;
      padding-bottom: 0.2vw;
    }

    label {
      color: #a6a7b8;
      font-size: 0.94vw;
      line-height: 1.9vw;
      margin-bottom: 0;
    }
  }

  @media (max-width: 576px) {
    .verify-email-wrapper {
      height: auto !important;

      .btn-link {
        font-size: inherit;
        line-height: inherit;
        font-weight: 100;
        margin-bottom: 1vw;
      }

      .footer {
        position: absolute;
        bottom: 15px;

        .copyright {
          font-size: 13px !important;
          line-height: 15px !important;
          font-weight: 100;
        }
      }
    }
  }
}
